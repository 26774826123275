import { getCommonParamsOptions } from '@/utils/common.js'
import { generateColLayout } from '@/utils/util'
export const baseViewFormConfig = {
  formItems: [
    {
      field: 'title',
      type: 'input',
      label: 'profile.title',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'articleType',
      type: 'select',
      label: 'profile.article-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('article_type'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'langType',
      type: 'select',
      label: 'profile.lang-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('lang_type'),
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'common.app-status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('status'),
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: generateColLayout(24),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'content',
      type: 'editor',
      label: 'profile.content',
      colLayout: generateColLayout(24),
      otherOptions: {
        disabled: true,
        isShowToolBar: false
      }
    }
  ]
}
