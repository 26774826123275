import { getCommonParamsOptions } from '@/utils/common.js'
import { generateColLayout } from '@/utils/util'
import { titleValidator } from '@/utils/validate'

export const baseNewFormConfig = {
  formItems: [
    {
      field: 'title',
      type: 'input',
      label: 'profile.title',
      otherOptions: {
        maxlength: 100
      }
    },
    {
      field: 'articleType',
      type: 'select',
      label: 'profile.article-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('article_type')
    },
    {
      field: 'langType',
      type: 'select',
      label: 'profile.lang-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('lang_type')
    },
    // {
    //   field: 'status',
    //   type: 'select',
    //   label: 'common.app-status',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('status')
    // },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: generateColLayout(24),
      otherOptions: {
        maxlength: 500
      }
    },
    {
      field: 'content',
      type: 'editor',
      label: 'profile.content',
      colLayout: generateColLayout(24)
    }
  ],
  validateRules: {
    articleType: [
      { required: true, message: 'general.required', trigger: 'change' }
    ],
    langType: [
      { required: true, message: 'general.required', trigger: 'change' }
    ],
    title: [
      { required: true, message: 'general.required', trigger: 'change' },
      { validator: titleValidator, trigger: 'change' }
    ],
    content: [
      { required: true, message: 'general.required', trigger: 'change' }
    ]
  }
}
